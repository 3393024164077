// src/services/indexedDBService.js

const DB_NAME = 'SageStoriesAudioDB';
const DB_VERSION = 1;
const AUDIO_STORE_NAME = 'audioFiles';
let db = null;

const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error("IndexedDB error:", event.target.error);
      reject("IndexedDB error");
    };
    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };
    request.onupgradeneeded = (event) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains(AUDIO_STORE_NAME)) {
        db.createObjectStore(AUDIO_STORE_NAME, { keyPath: 'id' });
      }
    };
  });
};

export const storeWhisperRecording = async (audioBlob) => {
  if (!db) await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([AUDIO_STORE_NAME], 'readwrite');
    const store = transaction.objectStore(AUDIO_STORE_NAME);
    const request = store.put({ id: 'recording.webm', audio: audioBlob }); // Changed to .webm
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve('recording.webm');
  });
};

export const storeTTSAudio = async (audioBlob, index) => {
  if (!db) await initDB();
  const fileName = `tts_${index}.mp3`; // Ensure .mp3 extension
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([AUDIO_STORE_NAME], 'readwrite');
    const store = transaction.objectStore(AUDIO_STORE_NAME);
    const request = store.put({ id: fileName, audio: audioBlob });
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(fileName);
  });
};

export const getAudio = async (fileName) => {
  if (!db) await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([AUDIO_STORE_NAME], 'readonly');
    const store = transaction.objectStore(AUDIO_STORE_NAME);
    const request = store.get(fileName);
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result ? request.result.audio : null);
  });
};

export const deleteAudio = async (fileName) => {
  if (!db) await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([AUDIO_STORE_NAME], 'readwrite');
    const store = transaction.objectStore(AUDIO_STORE_NAME);
    const request = store.delete(fileName);
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve();
  });
};

export const clearAllAudio = async () => {
  if (!db) await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([AUDIO_STORE_NAME], 'readwrite');
    const store = transaction.objectStore(AUDIO_STORE_NAME);
    const request = store.clear();
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve();
  });
};

export const getTTSAudioFiles = async () => {
  if (!db) await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([AUDIO_STORE_NAME], 'readonly');
    const store = transaction.objectStore(AUDIO_STORE_NAME);
    const request = store.getAll();
    request.onerror = () => reject(request.error);
    request.onsuccess = () => {
      const ttsFiles = request.result.filter(file => file.id.startsWith('tts_'));
      resolve(ttsFiles);
    };
  });
};

export const clearOldTTSAudio = async () => {
  if (!db) await initDB();
  const ttsFiles = await getTTSAudioFiles();
  const deletePromises = ttsFiles.map(file => deleteAudio(file.id));
  await Promise.all(deletePromises);
};
