// client/src/components/StarMap.js

import React from 'react';
import { seedsOfWisdom } from '../api/seedsdata';
import { StarMapGrid, StarItem } from './styles';

const StarMap = ({ 
  view, 
  figures, 
  selectedCategory, 
  selectedFigure, 
  categoryColors, 
  onCategoryClick, 
  onFigureClick, 
  onSeedClick 
}) => {
  const renderItems = () => {
    if (view === 'overall') {
      return figures.map((figure, index) => (
        <StarItem
          key={figure.name}
          style={{ backgroundColor: categoryColors[figure.category] }}
          onClick={() => onCategoryClick(figure.category)}
        >
          {figure.name}
        </StarItem>
      ));
    } else if (view === 'category') {
      return figures
        .filter(figure => figure.category === selectedCategory)
        .map((figure, index) => (
          <StarItem
            key={figure.name}
            style={{ backgroundColor: categoryColors[figure.category] }}
            onClick={() => onFigureClick(figure)}
          >
            {figure.name}
          </StarItem>
        ));
    } else if (view === 'figure') {
      return seedsOfWisdom
        .filter(seed => seed.name.startsWith(selectedFigure.name))
        .map((seed, index) => (
          <StarItem
            key={seed.name}
            className={seed.gathered ? 'gathered' : ''}
            style={{ backgroundColor: seed.gathered ? categoryColors[seed.category] : '#555' }}
            onClick={() => onSeedClick(seed)}
          >
            {seed.name.split(' - ')[1]}
          </StarItem>
        ));
    }
  };

  return (
    <StarMapGrid>
      {renderItems()}
    </StarMapGrid>
  );
};

export default StarMap;