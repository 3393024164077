import React, { useState, useCallback } from 'react';
import './TopBar.css';
import FigureCarousel from './FigureCarousel';
import InstructionsModal from './InstructionsModal';
import SettingsModal from './SettingsModal';

function TopBar({ selectedFigure, onSelectFigure, onOpenHistoryModal, onLogout }) {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleSelectFigure = useCallback((figure) => {
    onSelectFigure(figure);
    setIsCarouselOpen(false);
    try {
      localStorage.setItem('selectedFigure', figure.name);
      console.log('Saved selected figure to localStorage:', figure.name);
    } catch (error) {
      console.error('Error saving selected figure to localStorage:', error.message);
    }
  }, [onSelectFigure]);

  return (
    <div className="topbar">
      <div className="topbar-image-container">
        <img src={require(`../${selectedFigure.image}`)} alt={selectedFigure.name} className="topbar-image" />
      </div>
      <div className="topbar-content">
        <h1 className="topbar-title">{selectedFigure.name}</h1>
        <div className="topbar-buttons">
          <button className="topbar-button" onClick={() => setIsCarouselOpen(true)}>Choose Figure</button>
          <button className="topbar-button" onClick={onOpenHistoryModal}>History</button>
          <button className="topbar-button" onClick={() => setIsInstructionsOpen(true)}>Instructions</button>
          <button className="topbar-button" onClick={() => setIsSettingsOpen(true)}>Settings</button>
        </div>
      </div>
      <FigureCarousel 
        isOpen={isCarouselOpen} 
        onClose={() => setIsCarouselOpen(false)} 
        onSelectFigure={handleSelectFigure} 
      />
      <InstructionsModal
        isOpen={isInstructionsOpen}
        onClose={() => setIsInstructionsOpen(false)}
      />
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        onLogout={onLogout}
      />
    </div>
  );
}

export default TopBar;