// client/src/components/Header.js

import React from 'react';
import './Header.css';

function Header() {
  return (
    <div className="header">
      {/* Header content goes here */}
    </div>
  );
}

export default Header;
