// client/src/pages/DatenschutzPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LegalPages.css';

export default function DatenschutzPage() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="legal-page">
      <div className="legal-content">
        <button onClick={handleGoBack} className="back-button">
          Zurück
        </button>
        <h1 className="legal-title">Datenschutzerklärung</h1>

        <section className="legal-section">
          <h2>Präambel</h2>
          <p>
            Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z. B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als "Onlineangebot").
          </p>
          <p>
            Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
          </p>
          <p>Stand: 12. Oktober 2024</p>
          <p>
            <strong>Inhaltsübersicht</strong><br/>
            * Präambel<br/>
            * Verantwortlicher<br/>
            * Übersicht der Verarbeitungen<br/>
            * Maßgebliche Rechtsgrundlagen<br/>
            * Sicherheitsmaßnahmen<br/>
            * Internationale Datentransfers<br/>
            * Rechte der betroffenen Personen<br/>
            * Einsatz von Cookies<br/>
            * IP-Geolokation<br/>
            * Bereitstellung des Onlineangebotes und Webhosting<br/>
            * Plugins und eingebettete Funktionen sowie Inhalte<br/>
            * Cloudflare
          </p>
        </section>

        <section className="legal-section">
          <h2>Verantwortlicher</h2>
          <p>
            Michael Strasser<br/>
            Schusterstr. 50<br/>
            79098 Freiburg<br/>
            E-Mail-Adresse: chipmates@chipmates.ai
          </p>
        </section>

        <section className="legal-section">
          <h2>Übersicht der Verarbeitungen</h2>
          <p>
            Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.
          </p>
          <p>
            <strong>Arten der verarbeiteten Daten:</strong><br/>
            * Kontaktdaten<br/>
            * Inhaltsdaten<br/>
            * Nutzungsdaten<br/>
            * Meta-, Kommunikations- und Verfahrensdaten
          </p>
          <p>
            <strong>Kategorien betroffener Personen:</strong><br/>
            * Kommunikationspartner<br/>
            * Nutzer
          </p>
          <p>
            <strong>Zwecke der Verarbeitung:</strong><br/>
            * Kontaktanfragen und Kommunikation<br/>
            * Sicherheitsmaßnahmen<br/>
            * Verwaltung und Beantwortung von Anfragen<br/>
            * Feedback<br/>
            * Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit<br/>
            * Informationstechnische Infrastruktur
          </p>
        </section>

        <section className="legal-section">
          <h2>Maßgebliche Rechtsgrundlagen</h2>
          <p>
            <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO:</strong> Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
          </p>
          <ul>
            <li>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</li>
            <li>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>Sicherheitsmaßnahmen</h2>
          <p>
            Wir treffen nach Maßgabe der gesetzlichen Vorgaben geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten. Diese Maßnahmen umfassen insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten.
          </p>
          <p>
            Zu den Maßnahmen gehören die Kontrolle des physischen und elektronischen Zugangs zu den Daten, die Sicherung der Verfügbarkeit und ihrer Trennung sowie die Einrichtung von Verfahren zur Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktionen auf Gefährdungen der Daten.
          </p>
          <p>
            <strong>TLS/SSL-Verschlüsselung (https):</strong> Um die Daten der Benutzer zu schützen, verwenden wir TLS/SSL-Verschlüsselung. Diese gewährleistet die sichere Übertragung von Daten zwischen unserer Website und dem Browser des Nutzers.
          </p>
        </section>

        <section className="legal-section">
          <h2>Internationale Datentransfers</h2>
          <p>
            Sofern wir Daten in einem Drittland (außerhalb der EU oder des EWR) verarbeiten, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.
          </p>
          <p>
            Datentransfers in Drittländer erfolgen nur, wenn das Datenschutzniveau durch einen Angemessenheitsbeschluss anerkannt wurde, durch Standardvertragsklauseln, ausdrückliche Einwilligung oder im Rahmen gesetzlich erforderlicher Übermittlungen.
          </p>
        </section>

        <section className="legal-section">
          <h2>Rechte der betroffenen Personen</h2>
          <p>
            Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, darunter:
          </p>
          <ul>
            <li>Widerspruchsrecht</li>
            <li>Widerrufsrecht bei Einwilligungen</li>
            <li>Auskunftsrecht</li>
            <li>Recht auf Berichtigung</li>
            <li>Recht auf Löschung und Einschränkung der Verarbeitung</li>
            <li>Recht auf Datenübertragbarkeit</li>
            <li>Beschwerde bei einer Aufsichtsbehörde</li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>Einsatz von Cookies</h2>
          <p>
            Cookies sind kleine Textdateien, die Informationen auf Endgeräten speichern und Informationen aus den Endgeräten auslesen. Sie können zu verschiedenen Zwecken eingesetzt werden, etwa zur Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie zur Analyse der Besucherströme.
          </p>
          <p>
            Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Eine Einwilligung ist nur notwendig, wenn das Speichern und Auslesen der Informationen nicht unbedingt erforderlich ist.
          </p>
          <p>
            <strong>Speicherdauer:</strong><br/>
            * Temporäre Cookies: Diese werden spätestens gelöscht, nachdem der Nutzer das Online-Angebot verlassen hat.<br/>
            * Permanente Cookies: Diese bleiben auch nach dem Schließen des Endgeräts gespeichert.
          </p>
          <p>
            Nutzer können ihre Einwilligungen jederzeit widerrufen und der Verarbeitung widersprechen.
          </p>
        </section>

        <section className="legal-section">
          <h2>IP-Geolokation</h2>
          <p>
            Unsere Website verwendet optional die Funktion **IP-Geolokation**, die es uns ermöglicht, das Land des Website-Besuchers anhand seiner IP-Adresse zu erkennen. Diese Funktion wird verwendet, um:
          </p>
          <ul>
            <li>Inhalte basierend auf dem Standort des Nutzers dynamisch anzupassen (z. B. Sprache, Währung).</li>
            <li>länderspezifische Zugriffskontrollen oder Filter zu implementieren.</li>
            <li>statistische Daten über die geografische Herkunft unserer Besucher zu sammeln.</li>
          </ul>
          <p>
            Die **IP-Geolokation hat keinen Einfluss auf die Ladegeschwindigkeit** oder das **Caching-Verhalten** unserer Website. Diese Funktion bietet nur zusätzliche Informationen, die verwendet werden können, um Inhalte oder den Zugang basierend auf dem Standort des Nutzers zu optimieren.
          </p>
          <p>
            Sie können der Verarbeitung Ihrer IP-Adresse zu Geolokationszwecken widersprechen, indem Sie uns über [Kontaktinformationen] informieren oder entsprechende Einstellungen in Ihrem Browser vornehmen.
          </p>
          <p>
            Wenn die **IP-Geolokation deaktiviert** ist, werden keine standortbezogenen Daten verarbeitet, und der Zugriff auf die Website bleibt ohne Einschränkungen möglich.
          </p>
        </section>

        <section className="legal-section">
          <h2>Bereitstellung des Onlineangebotes und Webhosting</h2>
          <p>
          Wir verarbeiten die Daten der Nutzer, um unsere Online-Dienste zur Verfügung zu stellen. Zu diesem Zweck verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.
          </p>
          <p>
            <strong>Verarbeitete Datenarten:</strong><br/>
            * Nutzungsdaten<br/>
          </p>
          <p>
            <strong>Betroffene Personen:</strong><br/>
            * Nutzer
          </p>
          <p>
            <strong>Rechtsgrundlagen:</strong><br/>
            * Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
          </p>
          <p>
            <strong>Hosting und Content-Delivery-Netzwerke (CDN):</strong> Der Serveranbieter dieser Website ist Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen, Deutschland. Die Datenschutzerklärung von Hetzner finden Sie hier: <a href="https://www.hetzner.com/legal/privacy-policy">https://www.hetzner.com/legal/privacy-policy</a>.
          </p>
        </section>

        <section className="legal-section">
          <h2>Cloudflare</h2>
          <p>
            Wir nutzen das Content Delivery Network (CDN) von Cloudflare Germany GmbH, Rosental 7, c/o Mindspace, 80331 München Deutschland (Cloudflare), um die Sicherheit und die Auslieferungsgeschwindigkeit unserer Website zu erhöhen. Dies entspricht unserem berechtigten Interesse (Art. 6 Abs. 1 lit. f DSGVO). Ein CDN ist ein Netzwerk aus weltweit verteilten Servern, das in der Lage ist, optimiert Inhalte an den Websitenutzer auszuliefern. Für diesen Zweck können personenbezogene Daten in Server-Logfiles von Cloudflare verarbeitet werden. Bitte vergleichen Sie die Ausführungen unter „Hosting“.
          </p>
          <p>
            Cloudflare ist Empfänger Ihrer personenbezogenen Daten und als Auftragsverarbeiter für uns tätig. Dies entspricht unserem berechtigten Interesse im Sinne des Art. 6 Abs. 1 S. 1 lit. f DSGVO, selbst kein Content Delivery Network zu betreiben.
          </p>
          <p>
            Sie haben das Recht der Verarbeitung zu widersprechen. Ob der Widerspruch erfolgreich ist, ist im Rahmen einer Interessenabwägung zu ermitteln.
          </p>
          <p>
            Die Verarbeitung der unter diesem Abschnitt angegebenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Die Funktionsfähigkeit der Website ist ohne die Verarbeitung nicht gewährleistet.
          </p>
          <p>
            Ihre personenbezogenen Daten werden von Cloudflare so lange gespeichert, wie es für die beschriebenen Zwecke erforderlich ist.
          </p>
          <p>
            Weitere Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten gegenüber Cloudflare finden Sie unter: Cloudflare DPA
          </p>
          <p>
            Cloudflare hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Cloudflare personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Weitere Informationen finden Sie unter: <a href="https://www.cloudflare.com/cloudflare_customer_SCCs-German.pdf">https://www.cloudflare.com/cloudflare_customer_SCCs-German.pdf</a>.
          </p>
        </section>

        <section className="legal-section">
          <h2>Hinweis zu verwendeter Musik</h2>
          <p>
            Die auf dieser Webseite verwendeten Musikstücke wurden teils mit der KI-basierten Musikgenerationsplattform Udio (ein Produkt von Uncharted Labs, Inc.) erstellt. Die Nutzung der generierten Musikstücke erfolgt gemäß den Nutzungsbedingungen und Datenschutzrichtlinien von Udio.
          </p>
          <p>
            <a href="https://www.udio.com/terms-of-service">https://www.udio.com/terms-of-service</a><br/>
            <a href="https://www.udio.com/privacy-policy">https://www.udio.com/privacy-policy</a>
          </p>
          <p>
            Zusätzlich werden auf dieser Webseite auch Musikstücke verwendet, die nicht mit Udio generiert wurden. Diese Musikstücke sind ordnungsgemäß lizenziert und unterliegen den jeweiligen Lizenzbedingungen der Rechteinhaber. Eine vollständige Liste der verwendeten Musikstücke und der zugehörigen Lizenzbedingungen kann auf Anfrage zur Verfügung gestellt werden.
          </p>
        </section>

        <section className="legal-section">
          <h2>Streitschlichtung</h2>
          <p>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>
        </section>
      </div>
    </div>
  );
}
