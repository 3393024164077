import OpenAI from 'openai';
import Anthropic from '@anthropic-ai/sdk';
import { storeWhisperRecording, storeTTSAudio, getAudio, clearOldTTSAudio } from './indexedDBService';
import { Howl } from 'howler';

// API Keys
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const ANTHROPIC_API_KEY = process.env.REACT_APP_ANTHROPIC_API_KEY;

if (!OPENAI_API_KEY || !ANTHROPIC_API_KEY) {
  console.error('Missing API keys. Please check your .env file.');
}

// Initialize OpenAI and Anthropic clients
const openai = new OpenAI({ apiKey: OPENAI_API_KEY, dangerouslyAllowBrowser: true });
const anthropic = new Anthropic({ apiKey: ANTHROPIC_API_KEY, dangerouslyAllowBrowser: true });

// Event emitter for handling audio and text events
export const eventEmitter = {
  listeners: {},
  on(event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  },
  emit(event, data) {
    if (this.listeners[event]) {
      this.listeners[event].forEach(callback => callback(data));
    }
  },
  removeListener(event, callback) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
    }
  }
};

// Voice mapping for historical figures
const voiceMapping = {
  'Laozi': 'onyx',
  'Siddhartha Gautama': 'onyx',
  'Plato': 'onyx',
  'Arthur Schopenhauer': 'onyx',
  'Friedrich Nietzsche': 'onyx',
  'Carl Gustav Jung': 'onyx',
  'Simone de Beauvoir': 'nova',
  'Marcus Aurelius': 'onyx',
  'Rumi': 'onyx',
  'Joseph Campbell': 'onyx',
  'Dōgen Zenji': 'onyx',
  'Jesus of Nazareth': 'onyx',
  'Harriet Tubman': 'nova',
  'Mahatma Gandhi': 'onyx',
  'Nelson Mandela': 'onyx',
  'Martin Luther King Jr.': 'onyx',
  'Karl Marx': 'onyx',
  'Maya Angelou': 'nova',
  'William Shakespeare': 'onyx',
  'Johann Wolfgang von Goethe': 'onyx',
  'Jane Austen': 'nova',
  'Emily Dickinson': 'nova',
  'Virginia Woolf': 'nova',
  'Leonardo da Vinci': 'onyx',
  'Frida Kahlo': 'nova',
  'Ada Lovelace': 'nova',
  'Wolfgang Amadeus Mozart': 'onyx',
  'Albert Einstein': 'onyx',
  'Galileo Galilei': 'onyx',
  'Meister Eckhart': 'onyx'
};

// Default voice if figure is not found in the mapping
const DEFAULT_VOICE = 'nova';

/**
 * Get the appropriate voice for a given historical figure
 * @param {string} figureName - The name of the historical figure
 * @returns {string} The voice to be used for text-to-speech
 */
const getFigureVoice = (figureName) => {
  return voiceMapping[figureName] || DEFAULT_VOICE;
};

/**
 * Generate HTML from plain text, adding appropriate tags
 * @param {string} text - The input text
 * @returns {string} The HTML-formatted text
 */
const generateHTMLFromText = (text) => {
  return text
    .replace(/(?:\r\n|\r|\n)/g, '<br>')
    .replace(/^\s*•/gm, '<li>')
    .replace(/^\s*\d+\./gm, '<li>')
    .replace(/\n/g, '</li>\n')
    .replace(/<\/li>\n<li>/g, '</li><li>')
    .replace(/<li>([^<]+)<li>/g, '<li>$1</li><li>')
    .replace(/<li>\s*<\/li>/g, '')
    .replace(/<br>\s*<\/li>/g, '</li>')
    .replace(/<\/li>\s*<br>/g, '</li>');
};

/**
 * Fetch instructions for a given historical figure
 * @param {string} figure - The name of the historical figure
 * @returns {Promise<string>} The instructions for the figure
 */
const fetchInstructions = async (figure) => {
  console.log(`Fetching instructions for figure: ${figure}`);
  try {
    const instructions = await import(`../assets/instructions/${figure}.json`);
    console.log(`Received instructions for ${figure}:`, instructions.system);
    return instructions.system;
  } catch (error) {
    console.error(`Error fetching instructions for ${figure}:`, error);
    throw new Error(`Failed to fetch instructions for ${figure}`);
  }
};

/**
 * Determine the supported MIME type for audio recording
 * @returns {string} The supported MIME type
 */
const determineMimeType = () => {
  const mimeTypes = ['audio/webm', 'audio/ogg', 'audio/wav'];
  for (let type of mimeTypes) {
    if (MediaRecorder.isTypeSupported(type)) {
      return type;
    }
  }
  return 'audio/webm'; // Fallback to default
};

// Determine the supported MIME type once on module load
const supportedMimeType = determineMimeType();

/**
 * Process the recorded audio
 * @param {Blob} audioBlob - The recorded audio blob
 * @returns {Promise<Object>} The processed audio data
 */
export const processAudio = async (audioBlob) => {
  try {
    await storeWhisperRecording(audioBlob);

    const transcription = await transcribeAudio(audioBlob);
    console.log('Transcription successful:', transcription);

    eventEmitter.emit('textReady', { role: 'user', content: transcription });

    const selectedFigure = localStorage.getItem('selectedFigure') || 'Laozi';
    console.log('Selected figure:', selectedFigure);

    let conversationHistory = JSON.parse(localStorage.getItem(`history_${selectedFigure}`)) || [];
    console.log('Loaded conversation history:', conversationHistory);

    const instructions = await fetchInstructions(selectedFigure);

    conversationHistory.push({ role: 'user', content: transcription });

    console.log('Sending to Anthropic API:', {
      instructions,
      conversationHistory
    });

    await clearOldTTSAudio();

    const { responseText, audioFiles } = await generateChatResponseStream(instructions, conversationHistory, selectedFigure);
    console.log('Chat response generated and audio files created:', audioFiles);

    conversationHistory.push({ role: 'assistant', content: responseText });

    localStorage.setItem(`history_${selectedFigure}`, JSON.stringify(conversationHistory));
    console.log('Conversation history saved to localStorage');

    return { transcription, responseText, audioFiles };
  } catch (error) {
    console.error('Error during audio processing:', error);
    eventEmitter.emit('processingError', error);
    throw error;
  }
};

/**
 * Process a text message
 * @param {string} message - The text message
 * @param {string} figureName - The name of the historical figure
 * @param {boolean} hidden - Whether the message should be hidden in the chat history
 * @returns {Promise<Object>} The processed message data
 */
export const processTextMessage = async (message, figureName, hidden = false) => {
  try {
    const transcription = message;
    console.log('Received message:', transcription);

    eventEmitter.emit('textReady', { role: 'user', content: transcription, hidden });

    const selectedFigure = figureName;
    console.log('Selected figure:', selectedFigure);

    let conversationHistory = JSON.parse(localStorage.getItem(`history_${selectedFigure}`)) || [];

    const instructions = await fetchInstructions(selectedFigure);

    if (hidden) {
      conversationHistory.push({ role: 'user', content: transcription, hidden: true });
    } else {
      conversationHistory.push({ role: 'user', content: transcription });
    }

    console.log('Sending to Anthropic API:', {
      instructions,
      conversationHistory
    });

    await clearOldTTSAudio();

    const { responseText, audioFiles } = await generateChatResponseStream(instructions, conversationHistory, selectedFigure);
    console.log('Chat response generated and audio files created:', audioFiles);

    conversationHistory.push({ role: 'assistant', content: responseText });

    localStorage.setItem(`history_${selectedFigure}`, JSON.stringify(conversationHistory));
    console.log('Conversation history saved to localStorage');

    return { transcription, responseText, audioFiles };
  } catch (error) {
    console.error('Error during text message processing:', error);
    eventEmitter.emit('processingError', error);
    throw error;
  }
};

/**
 * Transcribe audio using OpenAI's Whisper model
 * @param {Blob} audioBlob - The audio blob to transcribe
 * @returns {Promise<string>} The transcribed text
 */
async function transcribeAudio(audioBlob) {
  try {
    const formData = new FormData();
    formData.append('file', audioBlob, `audio.${supportedMimeType.split('/')[1]}`);
    formData.append('model', 'whisper-1');

    const response = await openai.audio.transcriptions.create({
      file: formData.get('file'),
      model: 'whisper-1',
    });

    console.log('Received transcription:', response.text);
    return response.text;
  } catch (error) {
    console.error('Error transcribing audio:', error);
    throw error;
  }
}

/**
 * Generate a chat response stream using Anthropic's API
 * @param {string} instructions - The system instructions for the chat
 * @param {Array} conversationHistory - The conversation history
 * @param {string} figureName - The name of the historical figure
 * @returns {Promise<Object>} The generated response text and audio files
 */
async function generateChatResponseStream(instructions, conversationHistory, figureName) {
  console.log('Generating chat response with:', { instructions, conversationHistory });

  const messages = conversationHistory
    .filter(msg => msg.role === 'user' || msg.role === 'assistant')
    .map(msg => ({ role: msg.role, content: msg.content }));

  const stream = await anthropic.messages.create({
    model: "claude-3-5-sonnet-20240620",
    max_tokens: 4000,
    temperature: 1,
    system: instructions,
    messages: messages,
    stream: true,
  });

  let responseText = '';
  let sentence = '';
  let sentences = [];
  let fileIndex = 1;
  let batchSize = 1;
  const audioFiles = [];

  for await (const chunk of stream) {
    const content = chunk.delta?.text || '';
    responseText += content;
    sentence += content;

    if (sentence.endsWith('.') || sentence.endsWith('!') || sentence.endsWith('?')) {
      sentences.push(sentence);
      sentence = '';

      if (sentences.length >= batchSize) {
        const textChunk = sentences.join(' ');
        const audioFile = await convertTextToSpeech(textChunk, fileIndex, figureName);
        audioFiles.push(audioFile);

        eventEmitter.emit('audioReady', audioFile);
        eventEmitter.emit('textReady', { role: 'assistant', content: generateHTMLFromText(textChunk) });

        sentences = [];
        fileIndex += 1;
        batchSize += 1;
      }
    }
  }

  if (sentence || sentences.length > 0) {
    const remainingText = sentences.join(' ') + sentence;
    if (remainingText.trim()) {
      const audioFile = await convertTextToSpeech(remainingText, fileIndex, figureName);
      audioFiles.push(audioFile);
      eventEmitter.emit('audioReady', audioFile);
      eventEmitter.emit('textReady', { role: 'assistant', content: generateHTMLFromText(remainingText) });
    }
  }

  console.log('Generated response text:', responseText);
  console.log('Generated audio files:', audioFiles);

  return { responseText, audioFiles };
}

/**
 * Convert text to speech using OpenAI's TTS model
 * @param {string} text - The text to convert to speech
 * @param {number} fileIndex - The index of the audio file
 * @param {string} figureName - The name of the historical figure
 * @returns {Promise<Object>} The generated audio file information
 */
async function convertTextToSpeech(text, fileIndex, figureName) {
  const voice = getFigureVoice(figureName);
  console.log(`Converting text to speech for ${figureName} using voice: ${voice}`);

  try {
    const response = await openai.audio.speech.create({
      model: 'tts-1',
      voice: voice,
      input: text,
    });

    const audioBlob = new Blob([await response.arrayBuffer()], { type: 'audio/mpeg' });
    const fileName = await storeTTSAudio(audioBlob, fileIndex);

    const audioUrl = URL.createObjectURL(audioBlob);
    return { name: fileName, url: audioUrl };
  } catch (error) {
    console.error('Error converting text to speech:', error);
    throw error;
  }
}

/**
 * Initiate a conversation with a specific language and historical figure
 * @param {string} language - The language to use for the conversation
 * @param {string} figureName - The name of the historical figure
 * @returns {Promise<Object>} The initial conversation data
 */
export const initiateConversation = async (language, figureName) => {
  try {
    const initialMessage = `Let's speak in ${language}. Hello, who are you?`;
    await processTextMessage(initialMessage, figureName, true);
    return {};
  } catch (error) {
    console.error('Error initiating conversation:', error);
    eventEmitter.emit('conversationInitError', error);
    throw error;
  }
};

/**
 * Check if microphone permission is granted
 * @returns {Promise<boolean>} Whether microphone permission is granted
 */
export const checkMicrophonePermission = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    stream.getTracks().forEach(track => track.stop());
    return true;
  }
  catch (error) {
    console.error('Error checking microphone permission:', error);
    return false;
  }
};

/**
 * Utility function to handle errors and emit error events
 * @param {Error} error - The error object
 * @param {string} context - The context in which the error occurred
 */
const handleError = (error, context) => {
  console.error(`Error in ${context}:`, error);
  eventEmitter.emit('error', { context, message: error.message });
};

/**
 * Utility function to log important events
 * @param {string} message - The message to log
 * @param {Object} [data] - Additional data to log
 */
const logEvent = (message, data) => {
  console.log(message, data);
  eventEmitter.emit('log', { message, data });
};

// Export any additional utility functions or constants that might be useful
export {
  getFigureVoice,
  generateHTMLFromText,
  supportedMimeType,
  handleError,
  logEvent
};

// Add a cleanup function to release resources when needed
export const cleanup = () => {
  // Implement any necessary cleanup logic here
  logEvent('Cleaning up audio service resources');
};