import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import ChatBox from '../components/ChatBox';
import MessageInput from '../components/MessageInput';
import AboutModal from '../components/AboutModal';
import HistoryModal from '../components/HistoryModal';
import StartConversationButton from '../components/StartConversationButton';
import FigureCarousel from '../components/FigureCarousel';
import SettingsModal from '../components/SettingsModal';
import './HomePage.css';
import { historicalFigures } from '../api/figures';
import { eventEmitter, processTextMessage, initiateConversation } from '../services/audioService';
import { Howl } from 'howler';
import { checkPermission, requestPermission } from '../utils/PermissionHandler';

function HomePage({ onLogout }) {
  const [messages, setMessages] = useState([]);
  const [selectedFigure, setSelectedFigure] = useState(() => {
    const defaultFigure = historicalFigures.find(fig => fig.name === 'Laozi') || historicalFigures[0];
    return defaultFigure;
  });
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [conversationStarted, setConversationStarted] = useState(false);
  const [showFigureCarousel, setShowFigureCarousel] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userInteracted, setUserInteracted] = useState(() => {
    return localStorage.getItem('userInteracted') === 'true';
  });
  const [userPreferences, setUserPreferences] = useState({
    audio: localStorage.getItem('audioPreference') === 'true',
    microphone: localStorage.getItem('microphonePreference') === 'true',
    disableMagnifyingGlass: localStorage.getItem('disableMagnifyingGlass') === 'true'
  });
  const [permissionState, setPermissionState] = useState({
    audio: false,
    microphone: false
  });

  const fetchHistory = useCallback((figureName) => {
    const storedHistory = localStorage.getItem(`history_${figureName}`);
    if (storedHistory) {
      const parsedHistory = JSON.parse(storedHistory);
      setMessages(parsedHistory);
      setConversationStarted(parsedHistory.length > 0);
    } else {
      setMessages([]);
      setConversationStarted(false);
    }
  }, []);

  const checkAllPermissions = useCallback(async () => {
    const audioPermission = await checkPermission('audio');
    const microphonePermission = await checkPermission('microphone');
    setPermissionState({
      audio: audioPermission,
      microphone: microphonePermission
    });
    return audioPermission && microphonePermission;
  }, []);

  useEffect(() => {
    setSelectedFigure(historicalFigures.find(fig => fig.name === 'Laozi') || historicalFigures[0]);
    fetchHistory('Laozi');
    localStorage.setItem('selectedFigure', 'Laozi');

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    checkAllPermissions();

    const handleUserInteraction = () => {
      setUserInteracted(true);
      localStorage.setItem('userInteracted', 'true');
      window.removeEventListener('user-interaction', handleUserInteraction);
    };
    window.addEventListener('user-interaction', handleUserInteraction);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('user-interaction', handleUserInteraction);
    };
  }, [fetchHistory, checkAllPermissions]);

  useEffect(() => {
    const handleAudioReady = async (audioFile) => {
      console.log('Audio ready:', audioFile);
      setAudioQueue(prevQueue => [...prevQueue, audioFile]);
    };

    const handleTextReady = (textMessage) => {
      console.log('Text ready:', textMessage);
      addMessage(textMessage);
      if (textMessage.role === 'assistant') {
        setIsLoading(false);
      }
    };

    eventEmitter.on('audioReady', handleAudioReady);
    eventEmitter.on('textReady', handleTextReady);

    return () => {
      eventEmitter.removeListener('audioReady', handleAudioReady);
      eventEmitter.removeListener('textReady', handleTextReady);
    };
  }, []);

  const addMessage = useCallback((message) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[updatedMessages.length - 1];
      if (lastMessage && lastMessage.role === message.role) {
        updatedMessages[updatedMessages.length - 1] = {
          ...lastMessage,
          content: lastMessage.content + message.content
        };
      } else {
        updatedMessages.push(message);
      }
      localStorage.setItem(`history_${selectedFigure.name}`, JSON.stringify(updatedMessages));
      return updatedMessages;
    });
    setConversationStarted(true);
  }, [selectedFigure.name]);

  const handleSelectFigure = useCallback((figure) => {
    setSelectedFigure(figure);
    fetchHistory(figure.name);
    setShowFigureCarousel(false);
    try {
      localStorage.setItem('selectedFigure', figure.name);
      console.log('Saved selected figure to localStorage:', figure.name);
    } catch (error) {
      console.error('Error saving selected figure to localStorage:', error.message);
    }
  }, [fetchHistory]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0 && userInteracted) {
      playNextAudio();
    }
  }, [audioQueue, isPlaying, userInteracted]);

  const playNextAudio = async () => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    if (userPreferences.audio && !permissionState.audio) {
      const granted = await requestPermission('audio');
      if (!granted) {
        alert('Audio permission is required to play responses. Please grant permission in your browser settings.');
        setIsPlaying(false);
        return;
      }
      await checkAllPermissions();
    }

    setIsPlaying(true);

    const [nextAudio, ...remainingQueue] = audioQueue;
    setAudioQueue(remainingQueue);

    console.log(`Starting to play: ${nextAudio.name}`);

    const format = nextAudio.name.split('.').pop();
    const sound = new Howl({
      src: [nextAudio.url],
      format: [format],
      html5: true,
      onend: () => {
        console.log(`Finished playing: ${nextAudio.name}`);
        URL.revokeObjectURL(nextAudio.url);
        setIsPlaying(false);
      },
      onplayerror: (id, error) => {
        console.error('Error playing audio with Howler.js:', error);
        alert('Audio playback was blocked. Please ensure you have interacted with the app.');
        setIsPlaying(false);
      },
      onloaderror: (id, error) => {
        console.error('Error loading audio with Howler.js:', error);
        alert('Failed to load audio. Please try again.');
        setIsPlaying(false);
      }
    });

    sound.play();
  };

  const handleSendMessage = async (message) => {
    if (userPreferences.microphone && !permissionState.microphone) {
      const granted = await requestPermission('microphone');
      if (!granted) {
        alert('Microphone permission is required to send voice messages. Please grant permission in your browser settings.');
        return;
      }
      await checkAllPermissions();
    }

    try {
      setIsLoading(true);
      await processTextMessage(message, selectedFigure.name);
    } catch (error) {
      console.error('Error processing message:', error);
      addMessage({ role: 'system', content: 'An error occurred while processing your message. Please try again.' });
      setIsLoading(false);
    }
  };

  const handleLanguageSelected = (language) => {
    sendInitialMessage(language);
  };

  const sendInitialMessage = async (language) => {
    try {
      setIsLoading(true);
      await initiateConversation(language, selectedFigure.name);
      setConversationStarted(true);
      playInitialAudio();
    } catch (error) {
      console.error('Error sending initial message:', error);
      addMessage({ role: 'system', content: 'An error occurred while starting the conversation. Please try again.' });
      setIsLoading(false);
    }
  };

  const playInitialAudio = useCallback(() => {
    if (audioQueue.length > 0) {
      playNextAudio();
    }
  }, [audioQueue]);

  const handleOpenAboutModal = () => setIsAboutModalOpen(true);
  const handleCloseAboutModal = () => setIsAboutModalOpen(false);
  const handleOpenHistoryModal = () => setIsHistoryModalOpen(true);
  const handleCloseHistoryModal = () => setIsHistoryModalOpen(false);
  const handleOpenSettingsModal = () => setIsSettingsModalOpen(true);
  const handleCloseSettingsModal = (updatedPreferences) => {
    if (updatedPreferences) {
      setUserPreferences(updatedPreferences);
      localStorage.setItem('audioPreference', updatedPreferences.audio);
      localStorage.setItem('microphonePreference', updatedPreferences.microphone);
      localStorage.setItem('disableMagnifyingGlass', updatedPreferences.disableMagnifyingGlass);
      checkAllPermissions();
    }
    setIsSettingsModalOpen(false);
  };

  const handleSummaryGenerated = () => {
    fetchHistory(selectedFigure.name);
  };

  const handleHistoryCleared = () => {
    localStorage.removeItem(`history_${selectedFigure.name}`);
    setMessages([]);
    setConversationStarted(false);
  };

  const isMobileOrTablet = useMemo(() => windowWidth < 1024, [windowWidth]);

  return (
    <div className={`homepage ${isMobileOrTablet ? 'mobile-tablet' : 'desktop'}`}>
      {isMobileOrTablet ? (
        <TopBar
          selectedFigure={selectedFigure}
          onSelectFigure={handleSelectFigure}
          onOpenHistoryModal={handleOpenHistoryModal}
          onOpenSettingsModal={handleOpenSettingsModal}
          onLogout={onLogout}
        />
      ) : (
        <Sidebar
          selectedFigure={selectedFigure}
          onSelectFigure={handleSelectFigure}
          onOpenAboutModal={handleOpenAboutModal}
          onOpenHistoryModal={handleOpenHistoryModal}
          onOpenSettingsModal={handleOpenSettingsModal}
          onLogout={onLogout}
          isMobileOrTablet={isMobileOrTablet}
        />
      )}
      <div className="main-content justify-text">
        {showFigureCarousel ? (
          <FigureCarousel
            isOpen={true}
            onClose={() => {}}
            onSelectFigure={handleSelectFigure}
          />
        ) : (
          <>
            {!conversationStarted ? (
              <StartConversationButton onLanguageSelected={handleLanguageSelected} />
            ) : (
              <>
                <ChatBox 
                  messages={messages} 
                  selectedFigureName={selectedFigure.name} 
                  isLoading={isLoading}
                />
                <MessageInput 
                  onSendMessage={handleSendMessage} 
                  isDisabled={isLoading}
                  selectedFigure={selectedFigure}
                />
              </>
            )}
          </>
        )}
      </div>
      <AboutModal
        isOpen={isAboutModalOpen}
        onClose={handleCloseAboutModal}
        aboutText={selectedFigure.about}
        figureName={selectedFigure.name}
      />
      <HistoryModal
        isOpen={isHistoryModalOpen}
        onClose={handleCloseHistoryModal}
        selectedFigure={selectedFigure}
        onSummaryGenerated={handleSummaryGenerated}
        onHistoryCleared={handleHistoryCleared}
      />
      <SettingsModal
      isOpen={isSettingsModalOpen}
      onClose={handleCloseSettingsModal}
      onLogout={onLogout}
      userPreferences={userPreferences}
      descriptions={{
        audio: "Allow automatic audio playback to hear the chatbot's responses.",
        microphone: "Enable microphone access to use voice input for your messages.",
        magnifyingGlass: "Disable the magnifying glass for a cleaner interface."
      }}
    />
    </div>
  );
}

export default HomePage;