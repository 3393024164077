import React, { useState, useEffect } from 'react';
import './SettingsModal.css';

const SettingsModal = ({ isOpen, onClose, onLogout, userPreferences = {}, descriptions = {} }) => {
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [microphoneEnabled, setMicrophoneEnabled] = useState(false);
  const [disableMagnifyingGlass, setDisableMagnifyingGlass] = useState(false);

  useEffect(() => {
    setAudioEnabled(userPreferences.audio || false);
    setMicrophoneEnabled(userPreferences.microphone || false);
    setDisableMagnifyingGlass(userPreferences.disableMagnifyingGlass || false);
  }, [userPreferences]);

  const handleSave = () => {
    onClose({
      audio: audioEnabled,
      microphone: microphoneEnabled,
      disableMagnifyingGlass: disableMagnifyingGlass
    });
  };

  return (
    isOpen ? (
      <div className="modal-overlay" role="dialog" aria-modal="true">
        <div className="modal-content">
          <h2>Settings</h2>
          <div className="setting">
            <label>
              <input
                type="checkbox"
                checked={audioEnabled}
                onChange={(e) => setAudioEnabled(e.target.checked)}
              />
              {descriptions.audio || 'Allow automatic audio playback'}
            </label>
          </div>
          <div className="setting">
            <label>
              <input
                type="checkbox"
                checked={microphoneEnabled}
                onChange={(e) => setMicrophoneEnabled(e.target.checked)}
              />
              {descriptions.microphone || 'Enable microphone access'}
            </label>
          </div>
          <div className="setting">
            <label>
              <input
                type="checkbox"
                checked={disableMagnifyingGlass}
                onChange={(e) => setDisableMagnifyingGlass(e.target.checked)}
              />
              {descriptions.magnifyingGlass || 'Disable magnifying glass for a cleaner interface'}
            </label>
          </div>
          <div className="modal-actions">
            <button onClick={handleSave}>Save</button>
            <button onClick={() => onClose()}>Cancel</button>
            <button onClick={onLogout}>Logout</button>
          </div>
        </div>
      </div>
    ) : null
  );
};

export default SettingsModal;