// src/utils/PermissionHandler.js

export const checkPermission = async (permissionName) => {
  if (permissionName === 'microphone') {
    if (navigator.permissions && navigator.permissions.query) {
      try {
        const result = await navigator.permissions.query({ name: 'microphone' });
        return result.state === 'granted';
      } catch (error) {
        console.warn('Permissions API not supported for microphone');
        // Fallback: check if getUserMedia is available
        return !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
      }
    } else {
      // Fallback for browsers that don't support the Permissions API
      return !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
    }
  }
  if (permissionName === 'audio') {
    // Audio playback doesn't typically require permission, so we'll just check if the Web Audio API is supported
    return typeof AudioContext !== 'undefined' || typeof webkitAudioContext !== 'undefined';
  }
  return false;
};

export const requestPermission = async (permissionName) => {
  if (permissionName === 'microphone') {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
      return true;
    } catch (error) {
      console.error('Error requesting microphone permission:', error);
      return false;
    }
  }
  if (permissionName === 'audio') {
    // We can't directly request audio playback permission, so we'll just return true
    return true;
  }
  return false;
};