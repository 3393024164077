// client/src/components/MeteorShower.js

import React, { useRef, useEffect } from 'react';

const MeteorShower = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const meteors = [];
    const stars = [];

    const createMeteor = () => ({
      x: Math.random() * canvas.width,
      y: 0,
      size: Math.random() * 2.5 + 0.2,
      speed: Math.random() * 3 + 0.2,
      tail: [],
    });

    const createStar = () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      size: Math.random() * 1.5 + 0.5,
      brightness: Math.random() * 0.5 + 0.5,
    });

    const drawStars = () => {
      stars.forEach(star => {
        ctx.fillStyle = `rgba(255, 255, 255, ${star.brightness})`;
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2);
        ctx.fill();
      });
    };

    const animate = () => {
      ctx.fillStyle = 'rgba(28, 36, 92, 0.7)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    
      drawStars();

      meteors.forEach((meteor, index) => {
        ctx.fillStyle = '#D4A539';
        ctx.beginPath();
        ctx.arc(meteor.x, meteor.y, meteor.size, 0, Math.PI * 2);
        ctx.fill();

        meteor.tail.forEach((pos, i) => {
          ctx.fillStyle = `rgba(212, 165, 57, ${1 - i / 20})`;
          ctx.beginPath();
          ctx.arc(pos.x, pos.y, meteor.size * (1 - i / 20), 0, Math.PI * 2);
          ctx.fill();
        });

        meteor.y += meteor.speed;
        meteor.x += meteor.speed / 2;
        meteor.tail.unshift({ x: meteor.x, y: meteor.y });
        if (meteor.tail.length > 20) meteor.tail.pop();

        if (meteor.y > canvas.height) {
          meteors.splice(index, 1);
          meteors.push(createMeteor());
        }
      });

      requestAnimationFrame(animate);
    };

    for (let i = 0; i < 20; i++) {
      meteors.push(createMeteor());
    }

    for (let i = 0; i < 100; i++) {
      stars.push(createStar());
    }

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, zIndex: 10000 }} />;
};

export default MeteorShower;