// src/components/Spinner.js

import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.css';

const Spinner = ({ 
  size = 'large', 
  leftColor = '#D4A539', 
  rightColor = '#E97451', 
  blurAmount = '10px',
  backgroundColor = 'rgba(28, 36, 92, 0.7)',
  fullScreen = true, // New prop to control overlay
}) => {
  const spinnerClass = fullScreen ? 'spinner-overlay' : 'spinner-container';

  return (
    <div 
      className={spinnerClass} 
      style={{ 
        '--blur-amount': blurAmount, 
        '--background-color': backgroundColor 
      }}
    >
      <div className={`spinner spinner-${size}`} role="status" aria-label="Loading">
        <div className="lightsaber-container">
          <div className="ls-particles ls-part-1"></div>
          <div className="ls-particles ls-part-2"></div>
          <div className="ls-particles ls-part-3"></div>
          <div className="ls-particles ls-part-4"></div>
          <div className="ls-particles ls-part-5"></div>
          <div className="lightsaber ls-left" style={{ '--saber-color': leftColor }}></div>
          <div className="lightsaber ls-right" style={{ '--saber-color': rightColor }}></div>
        </div>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  leftColor: PropTypes.string,
  rightColor: PropTypes.string,
  blurAmount: PropTypes.string,
  backgroundColor: PropTypes.string,
  fullScreen: PropTypes.bool, // New prop type
};

export default Spinner;
