// src/components/ChatBox.js

import React, { useEffect, useRef } from 'react';
import { getAudio } from '../services/indexedDBService';
import './ChatBox.css';

function createMarkup(html) {
  return { __html: html };
}


const ChatBox = ({ messages, selectedFigureName, isLoading }) => {
  const chatBoxRef = useRef(null);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleAudioPlay = async (fileName) => {
    try {
      const audioBlob = await getAudio(fileName);
      if (audioBlob) {
        const audioUrl = URL.createObjectURL(audioBlob);
        audioRef.current.src = audioUrl;
        audioRef.current.play();
      } else {
        console.error('Audio file not found in IndexedDB');
      }
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  // Optionally filter out specific messages if needed
  const filteredMessages = messages.filter(message =>
    !(message.role === 'user' && message.content.startsWith("Hello, who are you? Let's speak in"))
  );

  return (
    <div className="chatbox" ref={chatBoxRef}>
      {filteredMessages.map((message, index) => (
        <div key={index} className={`message ${message.role}`}>
          <strong>{message.role === 'user' ? 'User: ' : `${selectedFigureName}: `}</strong>
          <span dangerouslySetInnerHTML={createMarkup(message.content)} />
          {/* If you wish to have audio playback buttons per message, ensure messages include an audioFile property */}
          {/* Uncomment the below if messages have audioFile */}
          {/* {message.audioFile && (
            <button onClick={() => handleAudioPlay(message.audioFile)}>Play Audio</button>
          )} */}
        </div>
      ))}
    </div>
  );
};

export default ChatBox;
