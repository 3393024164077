// client/src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import ImpressumPage from './pages/ImpressumPage';
import DatenschutzPage from './pages/DatenschutzPage';
import './styles/App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showFigureCarousel, setShowFigureCarousel] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = (username, password) => {
    setIsLoggedIn(true);
    setShowFigureCarousel(true);
    console.log(`Logged in as: ${username}`);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setShowFigureCarousel(true);
    // Clear any client-side data here if necessary
    localStorage.removeItem('selectedFigure');
    // You might want to clear IndexedDB data here as well
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/impressum" element={<ImpressumPage />} />
          <Route path="/datenschutz" element={<DatenschutzPage />} />
          <Route path="/" element={
            isLoggedIn ? (
              <HomePage 
                onLogout={handleLogout} 
                showFigureCarousel={showFigureCarousel} 
                setShowFigureCarousel={setShowFigureCarousel} 
              />
            ) : (
              <LoginPage onLogin={handleLogin} />
            )
          } />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;