// client/src/components/HistoryModal.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import './HistoryModal.css';
import { generateSummary, exportHistories, importHistories } from '../services/summaryService';

const ActionButton = ({ onClick, className, children }) => (
  <button onClick={onClick} className={`action-button ${className}`}>
    {children}
  </button>
);

const ImportButton = ({ onImport }) => (
  <label htmlFor="import-history" className="action-button import-button">
    Import Histories
    <input 
      type="file" 
      id="import-history" 
      accept="application/json" 
      onChange={onImport} 
      style={{ display: 'none' }} 
    />
  </label>
);

const Summary = ({ content }) => {
  const formatSummary = useCallback((text) => {
    return text.split('\n').map((line, index) => {
      if (line.startsWith('🔍') || line.startsWith('🔑') || line.startsWith('💬') || line.startsWith('✨')) {
        return <h3 key={index} style={{ color: '#D4A539' }}>{line}</h3>;
      } else if (line.trim().startsWith('•')) {
        return <li key={index}>{line.trim().substring(1)}</li>;
      } else if (line.trim().match(/^\d+\./)) {
        return <li key={index}>{line.trim().substring(line.indexOf('.') + 1)}</li>;
      } else if (line.trim() !== '') {
        return <p key={index}>{line}</p>;
      }
      return null;
    });
  }, []);

  return (
    <div className="summary-content">
      <div>{formatSummary(content)}</div>
    </div>
  );
};

const HistoryModal = ({ isOpen, onClose, selectedFigure, onSummaryGenerated, onHistoryCleared }) => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchHistory = useCallback(() => {
    setIsLoading(true);
    setError(null);
    try {
      const storedHistory = localStorage.getItem(`history_${selectedFigure.name}`);
      setHistory(storedHistory ? JSON.parse(storedHistory) : []);
    } catch (error) {
      console.error('Error loading history:', error);
      setError('Failed to load history');
    } finally {
      setIsLoading(false);
    }
  }, [selectedFigure.name]);

  useEffect(() => {
    if (isOpen) {
      fetchHistory();
    }
  }, [isOpen, fetchHistory]);

  const handleSummary = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await generateSummary(history, selectedFigure.name);
      fetchHistory();
      onSummaryGenerated();
    } catch (error) {
      console.error('Error generating summary:', error);
      setError('Failed to generate summary');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearFigureHistory = () => {
    if (window.confirm(`Are you sure you want to clear the history for ${selectedFigure.name}?`)) {
      try {
        localStorage.removeItem(`history_${selectedFigure.name}`);
        setHistory([]);
        onHistoryCleared();
      } catch (error) {
        console.error('Error clearing figure history:', error);
        setError('Failed to clear history');
      }
    }
  };

  const handleClearAllHistory = () => {
    if (window.confirm('Are you sure you want to clear the history for all figures? This action cannot be undone.')) {
      try {
        Object.keys(localStorage).forEach(key => {
          if (key.startsWith('history_')) {
            localStorage.removeItem(key);
          }
        });
        setHistory([]);
        onHistoryCleared();
      } catch (error) {
        console.error('Error clearing all history:', error);
        setError('Failed to clear all histories');
      }
    }
  };

  const handleExport = () => {
    try {
      const blob = exportHistories();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'sagestories_histories_backup.json';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting histories:', error);
      setError('Failed to export histories');
    }
  };

  const handleImport = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'application/json') {
      setError('Please upload a valid JSON file');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        setIsLoading(true);
        const blob = new Blob([e.target.result], { type: 'application/json' });
        await importHistories(blob);
        fetchHistory();
      } catch (error) {
        console.error('Error importing histories:', error);
        setError('Failed to import histories. Please ensure the JSON file is correctly formatted.');
      } finally {
        setIsLoading(false);
        event.target.value = null;
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const summary = useMemo(() => {
    return history.length > 0 && history[0].role === 'assistant' && history[0].content.includes('Conversation Overview') ? history[0] : null;
  }, [history]);

  const conversationHistory = useMemo(() => {
    return summary ? history.slice(1) : history;
  }, [summary, history]);

  if (!isOpen) return null;

  return (
    <div className="history-modal-overlay" role="dialog" aria-labelledby="history-modal-title">
      <div className="history-modal-content">
        <div className="modal-header">
          <h2 id="history-modal-title" className="modal-title">{selectedFigure.name} Conversation History</h2>
          <ActionButton onClick={onClose} className="close-button" aria-label="Close">Close</ActionButton>
        </div>
        {error && <div className="error-message" role="alert">{error}</div>}
        <div className="action-buttons-container">
          <ActionButton onClick={handleExport} className="export-button">Export Histories</ActionButton>
          <ImportButton onImport={handleImport} />
          <ActionButton onClick={handleClearFigureHistory} className="clear-button">Clear Figure History</ActionButton>
          <ActionButton onClick={handleClearAllHistory} className="clear-all-button">Clear All History</ActionButton>
          <ActionButton onClick={handleSummary} className="summary-button">Generate Summary</ActionButton>
        </div>
        {isLoading && <Spinner />}
        {summary && <Summary content={summary.content} />}
        <div className="history-content">
          {conversationHistory
            .filter(message => !message.hidden)
            .map((entry, index) => (
              <div key={index} className={`message ${entry.role}`}>
                <strong>{entry.role === 'user' ? 'User: ' : `${selectedFigure.name}: `}</strong>
                <span dangerouslySetInnerHTML={{ __html: entry.content }} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

HistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedFigure: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSummaryGenerated: PropTypes.func.isRequired,
  onHistoryCleared: PropTypes.func.isRequired,
};

export default HistoryModal;