// src/services/summaryService.js

import { OpenAI } from 'openai'; // Ensure you have the OpenAI SDK installed: npm install openai

/**
 * Initializes the OpenAI client with the API key from environment variables.
 * @returns {OpenAI} - The initialized OpenAI client.
 */
const initializeOpenAI = () => {
  const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  if (!OPENAI_API_KEY) {
    throw new Error('Missing OpenAI API key. Please set REACT_APP_OPENAI_API_KEY in your .env file.');
  }

  const openai = new OpenAI({
    apiKey: OPENAI_API_KEY,
    dangerouslyAllowBrowser: true, // WARNING: Exposes your API key to the browser
  });

  return openai;
};

const openai = initializeOpenAI();

/**
 * Generates a summary of the conversation using OpenAI's GPT-4 model.
 * After generating the summary, replaces the entire history with only the summary.
 * @param {Array} history - The conversation history.
 * @param {string} figureName - The name of the selected historical figure.
 * @returns {Promise<string>} - The generated summary.
 */
export const generateSummary = async (history, figureName) => {
  // Format the conversation history
  const formattedHistory = history
    .map((entry) => {
      return `${entry.role === 'user' ? 'User' : 'Chatbot'}: ${entry.content}`;
    })
    .join('\n');

  // Construct the prompt
  const prompt = `
Imagine you are an expert in creating summaries.
Summarize the provided chatbot conversation by identifying and concisely presenting the main topics discussed, the key questions asked by the user, and the most significant responses given by the chatbot. Ensure the summary is comprehensive, maintaining the context, nuances, and flow of the conversation, accurately reflecting both the user's concerns and the chatbot's insights. Strive for clarity, coherence, and balance between the user's inquiries and the chatbot's answers. Structure the summary in a visually appealing manner using headers, bullet points, numbered lists, symbols, and highlighting to enhance readability and presentation. Adapt the format as needed to suit the length and type of conversation.
Please merge old summaries with the new one. Old summaries should not be deleted.
Example of Ideal Summary Structure:

🔍 Conversation Overview:
Provide a brief overview of the entire conversation, including the main purpose and context. Summarize the user's primary goal and the chatbot's role in addressing it.

🔑 Main Topics Discussed:

Topic 1: Description of the first topic, including key points and context.
Topic 2: Description of the second topic, highlighting any significant details.
Topic 3: Description of the third topic, with emphasis on the most relevant aspects.
💬 Key Questions and Responses:

User Question 1:
Chatbot Response 1: Clear and concise explanation of the chatbot's response, highlighting key information.
User Question 2:
Chatbot Response 2: Detailed explanation of the chatbot's response, maintaining context and nuances.
User Question 3:
Chatbot Response 3: Summary of the chatbot's response, ensuring clarity and coherence.
✨ Highlighted Insights:

User Concerns: Briefly summarize the main concerns or issues raised by the user.
Chatbot Insights: Highlight the most significant insights or solutions provided by the chatbot.
If there is already a summary, please merge the old summary with the new one. Old summaries should not be deleted.
Conversation History:
${formattedHistory}
`;

  try {
    const response = await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'system', content: prompt }],
      max_tokens: 1500, // Adjust as needed
      temperature: 0.7,  // Adjust as needed
    });

    const summary = response.choices[0].message.content.trim();

    // Replace the entire history with only the summary
    const newHistory = [
      { role: 'assistant', content: summary }
    ];

    // Save the new history back to localStorage
    localStorage.setItem(`history_${figureName}`, JSON.stringify(newHistory));

    return summary;
  } catch (error) {
    console.error('Error generating summary:', error);
    throw error;
  }
};

/**
 * Exports all histories as a single JSON file.
 * @returns {Blob} - The JSON blob of all histories.
 */
export const exportHistories = () => {
  try {
    const allHistories = {};
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('history_')) {
        const figureName = key.replace('history_', '');
        allHistories[figureName] = JSON.parse(localStorage.getItem(key));
      }
    });
    const dataStr = JSON.stringify(allHistories, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    return blob;
  } catch (error) {
    console.error('Error exporting histories:', error);
    throw error;
  }
};

/**
 * Imports histories from a JSON blob.
 * @param {Blob} blob - The JSON blob containing histories.
 * @returns {Promise<void>}
 */
export const importHistories = async (blob) => {
  try {
    const text = await blob.text();
    const importedData = JSON.parse(text);

    if (typeof importedData !== 'object' || Array.isArray(importedData)) {
      throw new Error('Invalid JSON format.');
    }

    Object.entries(importedData).forEach(([figureName, historyData]) => {
      if (Array.isArray(historyData)) {
        localStorage.setItem(`history_${figureName}`, JSON.stringify(historyData));
      } else {
        console.warn(`Invalid history format for figure: ${figureName}`);
      }
    });
  } catch (error) {
    console.error('Error importing histories:', error);
    throw error;
  }
};
